import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import { Row, Col } from "reactstrap";
import PageTitleSection from "../components/PageTitleSection";
import truckHighway from "../images/truck-highway.jpg";
import starCircleIcon from "../images/star-circle.png";
import deliverIcon from "../images/deliver.png";
import phoneIcon from "../images/phone.png";
import dollarSqureIcon from "../images/dollar-square.png";
import peopleIcon from "../images/people.png";

const Section1 = () => {
  return (
    <Section className="about-section1">
      <Row>
        <Col lg={6}>
          <div className="as1-item">
            <h3>We Sweat the Details</h3>
            <p>
              We'll make sure every shipments is well planned out so you can
              have peace of mind.
            </p>
          </div>
          <div className="as1-item">
            <h3>We Honour Our World</h3>
            <p>
              We know how important your product is to you and we promise not to
              make promises we can't keep.
            </p>
          </div>
          <div className="as1-item">
            <h3>We Avoid Surprises</h3>
            <p>
              We know that our customers hate them. We'll communicate quickly,
              good news or bad, always do our best to make things right.
            </p>
          </div>
          <div className="as1-item">
            <h3>We Treat Customers like Family</h3>
            <p>Because we believe they are.</p>
          </div>
        </Col>
        <Col lg={6}>
          <img src={truckHighway} className="img-fluid" alt="" />
        </Col>
      </Row>
    </Section>
  );
};

const WhyEns = () => {
  return (
    <Section className="why-ens">
      <h2>
        <span className="text-primary">Why</span> ENS
        <span className="text-primary">?</span>
      </h2>
      <Row>
        <Col>
          <div className="why-ens-item">
            <img src={starCircleIcon} alt="" />
            <p className="text-primary">Satisfied customers across America</p>
          </div>
        </Col>
        <Col>
          <div className="why-ens-item">
            <img src={deliverIcon} alt="" />
            <p>We only make commitments which we can deliver</p>
          </div>
        </Col>
        <Col>
          <div className="why-ens-item">
            <img src={phoneIcon} alt="" />
            <p className="text-primary">24/7 dispatch and Customer Service</p>
          </div>
        </Col>
        <Col>
          <div className="why-ens-item">
            <img src={dollarSqureIcon} alt="" />
            <p>Best Price Guaranteed</p>
          </div>
        </Col>
        <Col>
          <div className="why-ens-item">
            <img src={peopleIcon} alt="" />
            <p className="text-primary">A Partner You Can Rely On</p>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

const AboutDescription = () => {
  return (
    <Section>
      <h2 className="mb-3">About ENS Logistics</h2>
      <p>
        Welcome to ENS Logistics, your number one source for the highest quality
        transportation services. ENS Logistics has been providing on-time and
        reliable logistic services across North America. Over the years, our
        reputation has grown due to our consistency and commitment towards
        serving our customers.ENS Logistics was built on a foundation that
        understands assets and has established strategic partnerships with
        carriers. Our experience gives us the ability to see the potential in
        your supply chain. We are inspired to take a customer first perspective
        and help you make changes, solve problems, that results in increase
        efficiencies for your business. ENS is small enough to treat every
        customer like they are our most valuable asset, and also big enough to
        get any kind of movement on time and cost efficiently. When you ship
        with ENS you ship with Family.
      </p>
    </Section>
  );
};

const About = () => (
  <Layout>
    <SEO title="About" />
    <PageTitleSection title="About ENS Logistics" />
    <Section1 />
    <WhyEns />
    <AboutDescription />
  </Layout>
);

export default About;
